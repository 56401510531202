$font-hel-roman: 'HelveticaNeueCyr-Roman', 'helveticaneuecyr-roman', sans-serif;
$font-hel-medium: 'HelveticaNeueCyr-Medium', 'helveticaneuecyr-medium', sans-serif;
$font-hel-bold: 'HelveticaNeueCyr-Bold', 'helveticaneuecyr-bold', sans-serif;


$font-exo-semibold: 'Exo2-SemiBold', 'exo2-semibold', sans-serif;
$font-exo-bold: 'Exo2-Bold', 'exo2-bold', sans-serif;
$font-exo-regular: 'Exo2-Regular', 'exo2-regular', sans-serif;



$orange: #FFD801;
$yellow: #F9E61E;
$blue: #0D96DD;


$trans: all .25s ease;

body {
  font-family: $font-hel-roman;
  font-size: 16px;
  line-height: 26px;
  color: #333;
  overflow-x: hidden;
}

a {
  outline: none;
}

.blue_btn {
  position: relative;
  display: block;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 19px;
  text-align: center;
  font-family: $font-exo-semibold;
  font-size: 14px;
  color: #fff;
  text-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
  text-transform: uppercase;
  background: linear-gradient(to bottom, rgba(0,164,240,1) 0%, rgba(0,138,236,1) 100%);
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 4px solid #236EC2;
  border-radius: 6px 6px 20px 20px;
  text-decoration: none !important;
  cursor: pointer;
  transition: $trans;

  &:hover {
    background: linear-gradient(to bottom, rgba(0,138,236,1) 0%, rgba(0,164,240,1) 100%);
  }
}

.blue_border_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $font-exo-semibold;
  font-size: 12px;
  color: #333;
  text-decoration: none !important;
  text-transform: uppercase;
  border: 2px solid $blue;
  border-radius: 6px 6px 20px 20px;
  transition: $trans;
  
  &:hover {
    background-color: $blue;
    color: #fff;
  }
}



//HEADER

header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding-top: 22px;
  background: transparent;
  z-index: 100;
  transition: $trans;

  &.scroll {
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: rgba(0,0,0,.85);
  }

  .logo_col {
    display: flex;
    align-items: center;

    a {
      display: flex;
      align-items: center;
      margin-right: 20px;
      text-decoration: none !important;
      font-family: $font-exo-semibold;
      font-size: 18px;
      text-transform: uppercase;
      color: #fff;

      span {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 41px;
        width: 48px;
        margin-right: 8px;
        font-family: $font-exo-semibold;
        font-size: 24px;
        color: #fff;
        background: url("../img/icons/logo_polygon.svg") no-repeat center;
        background-size: cover;
      }
    }

    .header_slogan {
      font-family: $font-exo-semibold;
      font-size: 12px;
      line-height: 18px;
      color: #fff;
      text-transform: uppercase;
    }
  }

  .menu_col {
    display: flex;
    align-items: center;

    ul {
      display: flex;
      margin-bottom: 0;
      padding-left: 0;
      list-style: none;
    }

    li {
      margin-right: 34px;

      &:last-of-type {
        margin-right: 0;
      }
    }

    a {
      font-family: $font-exo-semibold;
      font-size: 12px;
      text-transform: uppercase;
      color: #fff;
      text-decoration: none !important;
      border-bottom: 1px dashed rgba(255,255,255,.5);
      transition: $trans;

      &:hover {
        color: $yellow;
        border-bottom: 1px solid transparent;
      }
    }
  }

  .phone_col {

    p {
      margin-bottom: 5px;
      font-family: $font-exo-semibold;
      font-size: 12px;
      line-height: 18px;
      color: #fff;
      text-transform: uppercase;
    }

    a {
      font-family: $font-hel-roman;
      font-size: 19px;
      color: $yellow;
      text-decoration: none !important;
      transition: $trans;

      &:hover {
        color: #fff;
      }
    }
  }
}

//MAIN SCREEN

.main_screen {
  padding-top: 237px;
  padding-bottom: 166px;
  background: url("../img/main_screen_bg.jpg") no-repeat center;
  background-size: cover;

  h1 {
    margin-bottom: 69px;
    font-family: $font-exo-bold;
    font-size: 46px;
    line-height: 60px;
    color: #fff;
    text-transform: uppercase;
  }

  .calculator_btn {
    position: relative;
    display: block;
    max-width: 360px;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 19px;
    padding-left: 53px;
    font-family: $font-exo-semibold;
    font-size: 14px;
    color: #fff;
    text-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
    text-transform: uppercase;
    background: linear-gradient(to bottom, rgba(0,164,240,1) 0%, rgba(0,138,236,1) 100%);
    border-bottom: 4px solid #236EC2;
    border-radius: 6px 6px 20px 20px;
    text-decoration: none !important;
    transition: $trans;

    &:hover {
      background: linear-gradient(to bottom, rgba(0,138,236,1) 0%, rgba(0,164,240,1) 100%);

    }

    img {
      position: absolute;
      top: -6px;
      right: 37px;
    }
  }

  .col_right {
    position: relative;

    img {
      position: absolute;
      left: -80px;
    }
  }
}



//POPAP FORM

#popap-form {
  position: relative;
  max-width: 461px;
  margin-left: auto;
  margin-right: auto;

  h3 {
    font-family: $font-exo-semibold;
    font-size: 32px;
    color: #fff;
    text-align: center;
  }

  form {
    margin-left: auto;
    margin-right: auto;
    padding-top: 50px;
    padding-bottom: 50px;
    border-right: 0;
    border-left: 0;

    h4 {
      margin-bottom: 15px;
      font-size: 14px;
      color: #fff;
      text-align: center;
    }

    p {
      text-align: center;
    }
  }

  input {
    margin-bottom: 15px;
  }

  input[type=text]{
    position: relative;
    display: block;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 19px;
    padding-right: 40px;
    padding-left: 30px;
    font-family: $font-hel-roman;
    font-size: 16px;
    color: rgba(51,51,51,.5);
    border-radius: 6px 6px 20px 20px;
    transition: $trans;
    outline: none !important;
    background: #F6F6F6 url("../img/icons/input_user.png") no-repeat center right 30px;
    border: 1px solid transparent;

    &:hover, &:focus {
      border: 1px solid $blue;
    }
  }
}

.mfp-close-btn-in .mfp-close {
  color: #fff;
}

//WHY WE

.why_we {
  padding-top: 88px;
  padding-bottom: 60px;
  background-color: #F8F8F8;

  h2 {
    margin-bottom: 92px;
    font-family: $font-exo-semibold;
    font-size: 42px;
    text-align: center;
    text-transform: uppercase;
  }
}

.why_item {
  position: relative;
  display: flex;
  max-width: 521px;
  margin-bottom: 60px;
  background-color: #fff;
  box-shadow: 10px 15px 45px 0 rgba(0,0,0,0.06);

  &:after {
    content: url("../img/icons/why_label.jpg");
    position: absolute;
    top: -4px;
    left: 20px;
    border-radius: 4px 4px 0 0;
    z-index: 10;
  }
}

.why_desc {
  padding-top: 29px;
  padding-right: 40px;

  h3 {
    margin-bottom: 15px;
    font-family: $font-exo-semibold;
    font-size: 24px;
    line-height: 36px;
    text-transform: uppercase;
  }

  p {
    font-family: $font-hel-roman;
    font-size: 16px;
    line-height: 26px;
  }
}

//TYPES SCREED

.types_screed {
  padding-top: 86px;
  padding-bottom: 105px;
  background-color: #202020;

  h2 {
    max-width: 862px;
    margin-bottom: 141px;
    font-family: $font-exo-semibold;
    font-size: 42px;
    line-height: 56px;
    color: #fff;
    text-transform: uppercase;
  }
}

.screed_num {
  position: absolute;
  left: 0;
  top: -37px;
  font-family: $font-exo-bold;
  font-size: 84px;
  color: rgba(255,255,255,.3);
  z-index: 0;
}

img {
  position: relative;
  margin-bottom: 24px;
  z-index: 1;
}

.screed_item {
  position: relative;
  max-width: 293px;

  h4 {
    font-family: $font-hel-roman;
    font-size: 16px;
    line-height: 26px;
    color: #fff;
  }
}

//WHY SCREED

.why_screed {
  padding-top: 87px;

  h2 {
    font-family: $font-exo-semibold;
    font-size: 42px;
    text-align: center;
    text-transform: uppercase;
  }
}

.why_screed_col {

  &:nth-of-type(1) .why_screed_item{
    padding-top: 148px;
    background: url("../img/why_screed1.png") no-repeat left top;
  }

  &:nth-of-type(2) .why_screed_item{
    padding-top: 148px;
    background: url("../img/why_screed2.png") no-repeat left top 25px;
  }

  &:nth-of-type(3) .why_screed_item{
    padding-top: 141px;
    background: url("../img/why_screed3.png") no-repeat left top;
  }

  &:nth-of-type(4) .why_screed_item{
    padding-top: 141px;
    background: url("../img/why_screed4.png") no-repeat left top 63px;
  }

  &:nth-of-type(5) .why_screed_item{
    padding-top: 153px;
    background: url("../img/why_screed5.png") no-repeat left top 23px;
  }

  &:nth-of-type(6) .why_screed_item{
    padding-top: 153px;
    background: url("../img/why_screed6.png") no-repeat left top;
  }

  &:nth-of-type(7) .why_screed_item{
    padding-top: 131px;
    background: url("../img/why_screed7.png") no-repeat left top 20px;
  }

  &:nth-of-type(8) .why_screed_item{
    padding-top: 131px;
    background: url("../img/why_screed8.png") no-repeat left top;
  }

  &:nth-of-type(9) .why_screed_item{
    padding-top: 157px;
    background: url("../img/why_screed9.png") no-repeat left top 21px;
  }

  &:nth-of-type(10) .why_screed_item{
    padding-top: 157px;
    background: url("../img/why_screed10.png") no-repeat left top;
  }
}

.why_screed_item {
  max-width: 440px;
  margin-bottom: 79px;

  h4 {
    position: relative;
    margin-bottom: 31px;
    font-family: $font-exo-semibold;
    font-size: 24px;
    line-height: 36px;
    text-transform: uppercase;

    &:after {
      content: "";
      display: block;
      height: 3px;
      width: 30px;
      margin-top: 21px;
      background-color: $blue;
    }
  }

  p {
    font-family: $font-hel-roman;
    font-size: 16px;
    line-height: 26px;
  }
}

//ORDER FORM

.order_form {
  background-color: #202020;

  .col_left {
    position: relative;
    padding-top: 84px;
  }

  h2 {
    max-width: 560px;
    margin-bottom: 32px;
    font-family: $font-exo-semibold;
    font-size: 42px;
    line-height: 56px;
    color: #fff;
    text-transform: uppercase;
  }

  .clock {
    max-width: 194px;
    padding-left: 30px;
    font-family: $font-hel-roman;
    font-size: 16px;
    line-height: 26px;
    color: rgba(255,255,255,.8);
    background: url("../img/icons/clock.png") no-repeat left top 5px;
  }

  img {
    position: absolute;
    max-width: 430px;
    bottom: -7px;
    right: 0;
  }

  .col_right {
    display: flex;
    justify-content: flex-end;
  }

  form {
    height: 100%;
    max-width: 430px;
    padding-top: 90px;
    padding-bottom: 115px;
    padding-left: 68px;
    padding-right: 68px;
    border-left: 2px solid #363636;
    border-right: 2px solid #363636;

    p {
      margin-bottom: 0;
      font-family: $font-hel-roman;
      font-size: 12px;
      line-height: 18px;
      color: #fff;

      a {
        color: #3399ff;
        text-decoration: underline;

        &:hover {
          text-decoration: none !important;
        }
      }
    }
  }

  label {
    margin-bottom: 6px;
    font-family: $font-exo-semibold;
    font-size: 12px;
    color: rgba(255,255,255,.5);
    text-transform: uppercase;
  }

  input[type=tel]{
    position: relative;
    display: block;
    width: 100%;
    margin-bottom: 30px;
    padding-top: 20px;
    padding-bottom: 19px;
    padding-right: 40px;
    padding-left: 30px;
    font-family: $font-hel-roman;
    font-size: 16px;
    color: rgba(51,51,51,.5);
    border-radius: 6px 6px 20px 20px;
    transition: $trans;
    outline: none !important;
    background: #F6F6F6 url("../img/icons/input_phone.png") no-repeat center right 30px;
    border: 1px solid transparent;

    &:hover, &:focus {
      border: 1px solid $blue;
    }
  }

  .blue_btn {
    margin-bottom: 26px;
    outline: none !important;
  }
}


//DEFFERENCES

.differences {
  padding-top: 86px;
  padding-bottom: 120px;
  background-color: #f8f8f8;

  .mobile_table {
    display: none;
  }

  h2 {
    max-width: 670px;
    margin-bottom: 88px;
    margin-left: auto;
    margin-right: auto;
    font-family: $font-exo-semibold;
    font-size: 42px;
    line-height: 56px;
    text-transform: uppercase;
    text-align: center;
  }

  .table {
    max-width: 754px;
    margin-left: auto;
    margin-right: auto;
  }

  .table_header {

    .table_row {
      display: flex;
      border-bottom: 2px solid #f8f8f8;
    }

    .table_col {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 60px;
      width: 33.33333333%;
      font-family: $font-hel-medium;
      font-size: 16px;
      color: #fff;
      background-color: #2B84E6;
      border-right: 2px solid #f8f8f8;
    }
  }

  .table_body {

    .table_row {
      display: flex;
      border-bottom: 2px solid #f8f8f8;

      &:nth-of-type(odd){
        
        .table_col:nth-of-type(odd){
          background-color: #fff;
        }

        .table_col:nth-of-type(even){
          background-color: #EDF5FF;
        }
      }

      &:nth-of-type(even){

        .table_col:nth-of-type(odd){
          background-color: #FCFCFC;
        }

        .table_col:nth-of-type(even){
          background-color: #EAF0F5;
        }
      }
    }

    .table_col {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 60px;
      width: 33.33333333%;
      font-family: $font-hel-roman;
      font-size: 15px;
      color: rgba(51,51,51,.8);
      border-right: 2px solid #f8f8f8;
    }
  }
}

//APPLICATION AREA

.application_area {
  padding-top: 84px;

  h2 {
    max-width: 480px;
    margin-bottom: 89px;
    font-family: $font-exo-semibold;
    font-size: 42px;
    line-height: 56px;
    text-transform: uppercase;
  }

  .item_application {
    position: relative;
    max-width: 293px;
    margin-bottom: 80px;
    text-align: center;

    &:after {
      content: "";
      position: absolute;
      display: block;
      height: 40px;
      width: 6px;
      top: -20px;
      left: 30px;
      background-color: $blue;
      z-index: 10;
    }

    img {
      margin-bottom: 33px;
      box-shadow: 0 10px 50px 0 rgba(0,0,0,0.08);
    }

    h4 {
      margin-bottom: 25px;
      font-family: $font-exo-semibold;
      font-size: 24px;
      text-transform: uppercase;
    }

    .blue_border_btn {
      height: 44px;
      max-width: 226px;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
    }
  }

  p {
    max-width: 494px;
    margin-left: auto;
    margin-right: auto;
    font-family: $font-hel-roman;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
  }
}

//TEСHNOLOGY

.technology {
  padding-top: 87px;
  background-color: #F8F8F8;

  h2 {
    margin-bottom: 82px;
    font-family: $font-exo-semibold;
    font-size: 42px;
    text-align: center;
    text-transform: uppercase;
  }
}

.item_technology {
  max-width: 520px;
  margin-bottom: 79px;

  .img_wrap {
    display: flex;
    align-items: center;
    height: 154px;
    padding-left: 30px;
  }

  .desc {
    padding-top: 29px;
    padding-left: 35px;
    padding-right: 35px;
    padding-bottom: 30px;
    border: 5px solid #EDEDED;
    font-family: $font-hel-roman;

    b {
      font-family: $font-exo-bold;
    }
  }
}

//PROCESS DIAGRAM

.process_diagram {
  padding-top: 27px;
  padding-bottom: 40px;
  background-color: #F8F8F8;

  h2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 91px;
    padding-right: 66px;

    span {
      font-family: $font-exo-semibold;
      font-size: 42px;
      text-transform: uppercase;
    }

    a {
      font-family: $font-hel-roman;
      font-size: 16px;
      color: #3399ff;
      text-decoration: none !important;

      span {
        font-family: $font-hel-roman;
        font-size: 16px;
        color: #3399ff;
        text-transform: capitalize;
        border-bottom: 1px solid #3399ff;
        transition: $trans;
      }

      b {
        font-family: $font-hel-bold;
        font-size: 22px;
      }

      &:hover {

        span {
          border-bottom: 1px solid transparent;
        }
      }
    }
  }
}

.item_col {
  margin-bottom: 64px;
}

.item_col:nth-of-type(3) .item_process_diagram:after{
  content: none;
}

.item_process_diagram {
  max-width: 293px;
  position: relative;

  h4 {
    font-family: $font-hel-roman;
    font-size: 16px;
    line-height: 26px;
  }

  &:after {
    content: url("../img/icons/process_diagram_arrow.png");
    position: absolute;
    right: -72px;
    top: 70px;
  }
}

.item_process_diagram_last {
  height: 160px;
  max-width: 293px;
  padding-top: 27px;
  padding-left: 44px;
  padding-right: 44px;
  text-align: center;
  border: 5px solid #EDEDED;

  h5 {
    margin-bottom: 25px;
    font-family: $font-hel-bold;
    font-size: 16px;
  }

  .blue_btn {
    padding-top: 7px;
    padding-bottom: 10px;
    font-size: 12px;
    color: #fff !important;
  }
}

//COMPOSITION

.composition {
  padding-top: 87px;
  background-color: #202020;

  h2 {
    margin-bottom: 91px;
    font-family: $font-exo-semibold;
    font-size: 42px;
    color: #fff;
    text-transform: uppercase;
  }
}

.item_composition {
  max-width: 293px;
  margin-bottom: 112px;

  .img_wrap {
    display: flex;
    align-items: flex-end;
    height: 150px;
  }

  h4 {
    font-family: $font-exo-semibold;
    font-size: 24px;
    line-height: 36px;
    color: #fff;
    text-transform: uppercase;
  }

  p {
    font-family: $font-hel-roman;
    font-size: 16px;
    line-height: 26px;
    color: rgba(255,255,255,.8);
  }
}

//REVIEWS

.reviews {
  padding-top: 87px;
  padding-bottom: 100px;


  h2 {
    margin-bottom: 94px;
    font-family: $font-exo-semibold;
    font-size: 42px;
    text-align: center;
    text-transform: uppercase;
  }

  .slider_reviews_wrap {
    border: 5px solid #F4F4F4;
  }

  .review {
    padding-top: 30px;
    padding-left: 30px;
    padding-right: 40px;
    padding-bottom: 30px;

    .photo_wrap {
      max-width: 130px;
      float: left;
      margin-right: 30px;
    }

    h5 {
      font-family: $font-exo-semibold;
      font-size: 12px;
      text-transform: uppercase;
      text-align: center;
    }

    .desc {
      position: relative;

      &:after {
        content: '"';
        position: absolute;
        top: 21px;
        left: 139px;
        font-family: $font-exo-semibold;
        font-size: 150px;
        color: rgba(51,153,255,.10);
        z-index: 0;
      }
    }

    p {
      font-family: $font-hel-roman;
      font-size: 16px;
      line-height: 26px;
    }
  }

  .num_slide {
    padding-top: 15px;
    font-family: $font-exo-bold;
    font-size: 18px;
    color: rgba(51,51,51,.3);
  }

  .current_slide {
    font-size: 84px;
  }

  .arrow_slider_wrap {
    position: absolute;
    bottom: 0;
  }

  .prev_arrow {
    height: 16px;
    width: 47px;
    background: url("../img/icons/slider_arrow.png") no-repeat center;
    transform: rotate(180deg);
    transition: $trans;
    cursor: pointer;

    &:hover {
      background: url("../img/icons/slider_arrow_hover.png") no-repeat center;
    }
  }

  .next_arrow {
    position: absolute;
    top: -51px;
    height: 16px;
    width: 47px;
    background: url("../img/icons/slider_arrow.png") no-repeat center;
    transition: $trans;
    cursor: pointer;

    &:hover {
      background: url("../img/icons/slider_arrow_hover.png") no-repeat center;
    }
  }
}

//OPERATING PROCEDURE

.operating_procedure {
  padding-top: 86px;

  h2 {
    margin-bottom: 91px;
    font-family: $font-exo-semibold;
    font-size: 42px;
    text-transform: uppercase;
    text-align: center;
  }

  .item_operating {
    margin-bottom: 92px;
  }

  .num_operating {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 50px;
    margin-bottom: 30px;
    font-family: $font-exo-regular;
    font-size: 18px;
    color: $blue;
    border-radius: 50%;
    border: 2px solid $blue;
  }

  h4 {
    font-family: $font-exo-semibold;
    font-size: 24px;
    line-height: 36px;
    text-transform: uppercase;
  }
}

//VIDEO PHOTO

.video_photo {
  padding-top: 87px;
  padding-bottom: 100px;

  h2 {
    margin-bottom: 93px;
    font-family: $font-exo-semibold;
    font-size: 42px;
    text-transform: uppercase;
    text-align: center;
  }

  .video_wrap {
    max-width: 520px;
    margin-bottom: 40px;
  }

  .more_video {
    display: none;
  }

  .pretty-embed:after {
    height: 60px;
    width: 60px;
    background-image: url("../img/icons/video_play.png");
    z-index: 10;
  }

  .pretty-embed:hover:after {
    background-image: url("../img/icons/video_play_hover.png");
  }

  .embed-responsive {
    margin-bottom: 25px;
    background-color: #202020;
    box-shadow: 0 10px 50px 0 rgba(0,0,0,0.05);
  }

  .location {
    padding-left: 30px;
    font-family: $font-hel-roman;
    font-size: 14px;
    color: rgba(51,51,51,.8);
    background: url("../img/icons/location.png") no-repeat left center;
  }

  .time {
    padding-left: 30px;
    font-family: $font-hel-roman;
    font-size: 14px;
    color: rgba(51,51,51,.8);
    background: url("../img/icons/clock.png") no-repeat left center;
  }

  .blue_btn {
    max-width: 194px;
    margin-top: 34px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 7px;
    padding-bottom: 9px;
    font-size: 12px;
  }

  .col_right {

    .video_wrap {
      margin-left: auto;
    }
  }
}

//COAST

.coast {
  padding-top: 88px;
  padding-bottom: 75px;
  background-color: #F8F8F8;

  .mobile_table {
    display: none;
  }

  h2 {
    margin-bottom: 93px;
    font-family: $font-exo-semibold;
    font-size: 42px;
    text-transform: uppercase;
    text-align: center;
  }

  .table {
    margin-bottom: 50px;
  }

  .table_header {

    .table_row {
      display: flex;
      border-bottom: 2px solid #f8f8f8;
    }

    .table_col {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 60px;
      width: 33.33333333%;
      font-family: $font-hel-medium;
      font-size: 16px;
      color: #fff;
      background-color: #2B84E6;
      border-right: 2px solid #f8f8f8;
    }
  }

  .table_body {

    .table_row {
      display: flex;
      border-bottom: 2px solid #f8f8f8;

      &:nth-of-type(odd){

        .table_col {
          background-color: #fff;
        }

        .table_col:first-of-type {
          background-color: #EDF5FF;
        }
      }

      &:nth-of-type(even){

        .table_col {
          background-color: #fcfcfc;
        }

        .table_col:first-of-type {
          background-color: #EAF0F5;
        }
      }
    }

    .table_col {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 60px;
      width: 33.33333333%;
      font-family: $font-hel-roman;
      font-size: 15px;
      color: rgba(51,51,51,.8);
      border-right: 2px solid #f8f8f8;
      text-align: center;
    }
  }

  .blue_border_block {
    max-width: 420px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 12px;
    padding-bottom: 13px;
    padding-left: 15px;
    padding-right: 15px;
    font-family: $font-hel-bold;
    font-size: 16px;
    text-align: center;
    border: 4px solid #91BEEF;
  }
}

//STOCK

.stock {
  padding-top: 91px;
  padding-bottom: 34px;
  background: url("../img/stock_bg.jpg") no-repeat center;
  background-size: cover;

  .col_left {

    h2 {
      margin-bottom: 12px;
      font-family: $font-exo-semibold;
      font-size: 42px;
      color: #fff;
      text-transform: uppercase;
    }

    h4 {
      margin-bottom: 27px;
      font-family: $font-exo-semibold;
      font-size: 24px;
      line-height: 36px;
      color: #fff;
      text-transform: uppercase;
    }
  }

  .stock_clock {
    margin-bottom: 24px;
    padding-left: 30px;
    background: url("../img/icons/clock.png") no-repeat left center;
    font-family: $font-hel-roman;
    font-size: 16px;
    color: rgba(255,255,255,.8);
  }
  
  #timerb74d8f6629d1c4409cef3b4723cab625 {
    text-align: left !important;
  }

  #timerb74d8f6629d1c4409cef3b4723cab625 .timer-slide-inner {

    height: 64px;
    width: 40px;
    font-family: $font-exo-semibold;
    font-size: 28px;
    color: #333;
    border-radius: 6px 6px 14px 14px;

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
    }
  }

  #timerb74d8f6629d1c4409cef3b4723cab625 .timer-separator {
    padding-top: 20px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    color: #fff !important;
    font-size: 25px !important;
  }

  #timer-text-b74d8f6629d1c4409cef3b4723cab625-hours,
  #timer-text-b74d8f6629d1c4409cef3b4723cab625-minutes,
  #timer-text-b74d8f6629d1c4409cef3b4723cab625-secundes {
    font-family: $font-exo-semibold !important;
    font-size: 12px !important;
    color: rgba(255,255,255,.5) !important;
    text-transform: uppercase !important;
  }



  .col_right {

  }

  form {
    max-width: 640px;

    label {
      margin-bottom: 6px;
      font-family: $font-exo-semibold;
      font-size: 12px;
      color: rgba(255,255,255,.5);
      text-transform: uppercase;
    }

    input[type=tel],
    input[type=text] {
      position: relative;
      display: block;
      width: 100%;
      margin-bottom: 10px;
      padding-top: 18px;
      padding-bottom: 17px;
      padding-right: 50px;
      padding-left: 30px;
      font-family: $font-hel-roman;
      font-size: 16px;
      color: rgba(51,51,51,.5);
      border-radius: 6px 6px 20px 20px;
      transition: $trans;
      outline: none !important;
      border: 1px solid transparent;

      &:hover, &:focus {
        border: 1px solid $blue;
      }
    }

    input[name=name]{
      background: #f6f6f6 url("../img/icons/input_user.png") no-repeat center right 30px;
    }

    input[name=square]{
      background: #f6f6f6 url("../img/icons/input_square.png") no-repeat center right 30px;
    }

    input[name=phone]{
      background: #f6f6f6 url("../img/icons/input_phone.png") no-repeat center right 30px;
    }

    .blue_btn {
      margin-top: 32px;
      padding-top: 17px;
      padding-bottom: 16px;
      outline: none !important;
    }
  }

  .col_conf {
    margin-left: 50%;
    margin-top: 16px;

    p {
      margin-bottom: 0;
      font-family: $font-hel-roman;
      font-size: 12px;
      line-height: 18px;
      color: #fff;

      a {
        color: #3399ff;
        text-decoration: underline;

        &:hover {
          text-decoration: none !important;
        }
      }
    }
  }
}

//FOOTER

footer {
  background-color: #f8f8f8;
  padding-top: 36px;
  padding-bottom: 44px;
}

.top_footer {

  .col_left {
    padding-top: 18px;
  }

  .col_left a {
    display: flex;
    align-items: center;
    margin-bottom: 14px;
    font-family: $font-exo-semibold;
    font-size: 18px;
    color: #333 !important;
    text-decoration: none !important;
    text-transform: uppercase;

    img {
      margin-right: 10px;
      margin-bottom: 0;
    }
  }

  .slogan {
    font-family: $font-exo-semibold;
    font-size: 12px;
    line-height: 18px;
    text-transform: uppercase;
  }

  .col_center {
    padding-top: 25px;
  }


  ul {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;

    li {
      margin-right: 34px;

      &:last-of-type {
        margin-right: 0;
      }

      a {
        font-family: $font-hel-roman;
        font-size: 12px;
        text-transform: uppercase;
        color: #333;
        border-bottom: 1px dashed rgba(51,51,51,.7);
        text-decoration: none !important;
        transition: $trans;

        &:hover {
          color: $blue;
          border-bottom: 1px solid transparent;
        }
      }
    }
  }

  .col_right {

    p {
      margin-bottom: 4px;
      font-family: $font-exo-semibold;
      font-size: 12px;
      line-height: 18px;
      text-transform: uppercase;
      color: rgba(51,51,51,.8);
    }
  }

  .footer_phone_wrap {
    max-width: 194px;
    margin-left: auto;

    .blue_btn {
      margin-top: 22px;
      padding-top: 7px;
      padding-bottom: 9px;
      font-size: 12px;
      color: #fff;
    }
  }

  .footer_tel {
    font-family: $font-hel-roman;
    font-size: 22px;
    color: #333;
    transition: $trans;
    text-decoration: none !important;

    &:hover {
      color: $blue;
    }
  }
}

.bottom_footer {
  padding-top: 42px;

  &>.container {
    display: flex;
    justify-content: space-between;
    font-family: $font-hel-roman;
    font-size: 12px;
    color: rgba(51,51,51,.8);

    a {
      color: #3399ff;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

                                      //PRIVACY PAGE

.privacy_page {

  header {
    padding-bottom: 15px;
    background-color: rgba(0, 0, 0, 0.85) !important;
  }
}


.privacy {
  padding-top: 200px;
  padding-bottom: 100px;
  
  li {
    margin-bottom: 10px;
    color: $blue;
    
    span {
      color: #333;
    }
  }

  h2 {
    margin-bottom: 30px;
    font-family: "Exo2-SemiBold", "exo2-semibold", sans-serif;
    font-size: 42px;
    text-align: center;
    text-transform: uppercase;
  }

  h3 {
    margin-bottom: 30px;
    font-family: "Exo2-SemiBold", "exo2-semibold", sans-serif;
    font-size: 24px;
    line-height: 36px;
    text-transform: uppercase;
    text-align: center;
  }

  h4 {
    font-family: "Exo2-SemiBold", "exo2-semibold", sans-serif;
    font-size: 20px;
    line-height: 36px;
    text-transform: uppercase;
  }

  p {
    font-family: "HelveticaNeueCyr-Roman", "helveticaneuecyr-roman", sans-serif;
    font-size: 16px;
    line-height: 26px;

    a {
      transition: $trans;
      border-bottom: 1px solid $blue;

      &:hover {
        color: $yellow;
        text-decoration: none !important;
        border-bottom: 0;
      }
    }
  }
}

                                          //THANKS PAGE
.thanks_block {
  text-align: center !important;

  h1 {
    margin-bottom: 30px;
  }

  h5 {
    color: #fff;

    a {
      color: $blue;
      border-bottom: 1px solid $blue;
      text-decoration: none !important;
      transition: $trans;

      &:hover {
        color: $yellow;
        border-bottom: 1px solid transparent;
      }
    }
  }
}